import React, { useEffect, useState, useRef } from "react";
import "./index.css";

const NotificationBar = ({ notification, onHeightChange }) => {
    const [isVisible, setIsVisible] = useState(true);
    const barRef = useRef(null);

    useEffect(() => {
        if (isVisible && barRef.current) {
            onHeightChange(barRef.current.offsetHeight);
        } else {
            onHeightChange(0);
        }
    }, [isVisible, onHeightChange]);

    if (!notification || !isVisible) return null;

    const {
        description,
        background_color,
        description_text_color,
        activate_cta,
        texto_url_cta,
        link_cta,
        on_close,
        animacion,
        animation_speed,
    } = notification.acf;

    return (
        <div
            ref={barRef}
            className="notification-bar"
            style={{
                backgroundColor: background_color || "#000",
                color: description_text_color || "#fff",
                "--animation-speed": `${animation_speed || 10}s`,
            }}
        >
            <div
                className={`notification-bar-content ${animacion ? "animate-slide" : ""}`}
            >
                {/* Descripción */}
                <span>{description}&nbsp;</span>

                {/* CTA como enlace */}
                {activate_cta && texto_url_cta && link_cta && (
                    <a
                        href={link_cta}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="notification-bar-cta"
                        style={{ color: description_text_color || "#fff" }}
                    >
                        {texto_url_cta}
                    </a>
                )}
            </div>

            {/* Enlace para cerrar la barra */}
            {on_close && (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsVisible(false);
                    }}
                    className="notification-bar-close"
                    style={{ color: description_text_color || "#fff" }}
                >
                    &times;
                </a>
            )}
        </div>
    );
};

export default NotificationBar;
