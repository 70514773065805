import React from "react";
import {
  AppBarComponent,
  BreadcumbItem,
  CloseButton,
  GoBackButton,
  HeaderBarComponent,
  HeaderComponent,
  IconButton,
  Location,
  WelcomeMessage,
} from "./styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import useWindowSize from "../../hooks/useWindowSize";
import { size as windowSizes } from "../../utils/device";
import { useNotification } from "../../context/notificationbar/notificationBarContext";

const Header = ({ onPrevious, onClose, breadcumbs }) => {
  const { barHeight } = useNotification();
  const { width } = useWindowSize();

  const isSmallWindow = width < windowSizes.desktopS;

  return (
    <HeaderComponent marginTop={`${barHeight}px`}>
      {isSmallWindow && breadcumbs && (
        <AppBar
          breadcumbs={breadcumbs}
          onPrevious={onPrevious}
          onClose={onClose}
        />
      )}
      {!isSmallWindow && breadcumbs && <HeaderBar breadcumbs={breadcumbs} />}
      {breadcumbs === undefined && (
        <WelcomeMessage>Bienvenido a AFP Habitat</WelcomeMessage>
      )}
    </HeaderComponent>
  );
};

export default Header;

export const AppBar = ({ breadcumbs, onPrevious, onClose }) => {
  const current = breadcumbs.find((e) => e.current);

  return (
    <AppBarComponent>
      {onPrevious && (
        <GoBackButton onClick={onPrevious}>
          <BiArrowBack />
        </GoBackButton>
      )}
      <Location>{current?.label}</Location>
      {onClose && (
        <CloseButton onClick={onClose}>
          <MdOutlineClose />
        </CloseButton>
      )}
    </AppBarComponent>
  );
};

export const HeaderBar = ({ breadcumbs }) => {
  return (
    <HeaderBarComponent>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        {breadcumbs?.map(({ label, path, current }) => {
          return (
            <BreadcumbItem
              key={`${label}-${path}`}
              as={current && "span"}
              current={current}
              underline="hover"
              color="inherit"
              to={path}
            >
              {label}
            </BreadcumbItem>
          );
        })}
      </Breadcrumbs>
    </HeaderBarComponent>
  );
};
