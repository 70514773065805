import React, { useEffect, useRef } from "react";
import user from "../../../../assets/icons/user-vector.png";
import Blog from "../../../../assets/icons/blog-icon.svg";
import Reforma from "../../../../assets/icons/reforma-icon.svg";
import SPP from "../../../../assets/icons/spp-icon.svg";
import Video from "../../../../assets/icons/video-icon.svg";

import DolarCoin from "../../../../assets/icons/dolar-coin-icon.svg";
import Coin from "../../../../assets/icons/coin-icon.svg";
import Wallet from "../../../../assets/icons/wallet-icon.svg";
import SaveMoney from "../../../../assets/icons/save-money-icon.svg";

import SubMenu1 from "../../../../assets/img/submenu1.png";
import SubMenu2 from "../../../../assets/img/submenu2.png";
import SubMenu3 from "../../../../assets/img/submenu3.png";
import SubMenu4 from "../../../../assets/img/submenu4.png";
import SubMenu5 from "../../../../assets/img/submenu5.png";
import SubMenu6 from "../../../../assets/img/submenu6.png";
import SubMenu7 from "../../../../assets/img/submenu7.png";

import whietDownArrow from "../../../../assets/icons/white-down-arrow.svg";
import { HeaderRowOption } from "./components";
import { useHistory } from "react-router-dom";
import "./index.css";

const Header = ({ barHeight }) => {
  const history = useHistory();
  const containerRefOption1 = useRef(null);
  const containerRefOption2 = useRef(null);
  const containerRefOption3 = useRef(null);
  const containerRefOption4 = useRef(null);
  const containerRefOption5 = useRef(null);
  const containerRefOption6 = useRef(null);

  const irAlLogin = () => {
    history.push("/mi-habitat-digital/empezar");
  };
  const onClickBars = () => {
    const elements = document.getElementsByClassName("bar");
    const mButton = document.getElementById("menuButton-main");
    for (let i = 0; i < elements.length; i++)
      if (elements[i].className.includes("change")) {
        elements[i].className = elements[i].className.replace(" change", "");
        mButton.className = mButton.className.replace(" change", "");
      } else {
        elements[i].className += " change";
        mButton.className += " change";
      }
  };

  const headerOptions = [
    {
      title: "Aportes y jubilación",
      subOptions: [
        {
          title: "Aporte obligatorio",
          url: "https://www.afphabitat.com.pe/aporte-obligatorio/",
        },
        {
          title: "Aporte voluntario",
          url: "https://www.afphabitat.com.pe/aporte-voluntario/",
        },
        {
          title: "Multifondos",
          url: "https://www.afphabitat.com.pe/multifondos/",
        },
        {
          title: "Jubilación",
          url: "https://www.afphabitat.com.pe/pensiones/",
        },
      ],
    },
    {
      title: "Etapas de la vida",
      subOptions: [
        {
          title: "Pronto voy a aportar",
          url: "https://www.afphabitat.com.pe/pronto-voy-a-aportar/",
        },
        {
          title: "Estoy aportando hace poco",
          url: "https://www.afphabitat.com.pe/estoy-aportando-hace-poco/",
        },
        {
          title: "He aportado varios años",
          url: "https://www.afphabitat.com.pe/he-aportado-varios-anos/",
        },
        {
          title: "Soy trabajador independiente",
          url: "https://www.afphabitat.com.pe/soy-trabajador-independiente/",
        },
        {
          title: "Estoy próximo a jubilarme",
          url: "https://www.afphabitat.com.pe/estoy-proximo-a-jubilarme/",
        },
        {
          title: "Me jubilé del trabajo",
          url: "https://www.afphabitat.com.pe/soy-jubilado/",
        },
        {
          title: "Soy beneficiario o heredero",
          url: "https://www.afphabitat.com.pe/soy-beneficiario-o-heredero/",
        },
      ],
    },
    {
      title: "Cámbiate a HABITAT",
      subOptions: null,
      url: "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=menu&utm_campaign=traspasos",
    },
    {
      title: "Rentabilidad",
      subOptions: null,
      url: "https://www.afphabitat.com.pe/rentabilidad/",
    },
    {
      title: "Aprenda",
      subOptions: [
        {
          title: "Blog",
          url: "https://www.afphabitat.com.pe/aprende-de-prevision/",
        },
        {
          title: "SPP",
          url: "https://www.afphabitat.com.pe/todo-sobre-el-sistema/",
        },
        {
          title: "Reforma AFP",
          url: "https://www.afphabitat.com.pe/reforma-del-sistema-de-pensiones/",
        },
        {
          title: "Fácil en videos",
          url: "https://www.afphabitat.com.pe/facil-en-videos/",
        },
      ],
    },
    {
      title: "Atención al cliente",
      subOptions: null,
      url: "https://www.afphabitat.com.pe/canales-de-atencion/",
    },
    {
      title: "Empleadores",
      subOptions: null,
      url: "https://www.afphabitat.com.pe/empleadores/",
    },
  ];

  const goToUrl = (url) => {
    window.open(url, "_self").focus();
  };

  const toogleFocusOption1 = () => {
    const container = document.getElementById("option-1");
    container.style.display = "flex";
  };

  const toogleFocusOption2 = () => {
    const containerOption2 = document.getElementById("option-2");
    containerOption2.style.display = "flex";
    const containerOption1 = document.getElementById("option-1");
    containerOption1.style.display = "none";
  };

  const toogleFocusOption3 = () => {
    const containerOption2 = document.getElementById("option-2");
    containerOption2.style.display = "none";
    const containerOption1 = document.getElementById("option-1");
    containerOption1.style.display = "none";
  };

  const toogleFocusOption4 = () => {
    const containerOption4 = document.getElementById("option-4");
    containerOption4.style.display = "flex";
  };

  const toogleFocusOption5 = () => {
    const containerOption4 = document.getElementById("option-4");
    containerOption4.style.display = "none";
  };

  const handleLeave = (number_option) => {
    if (number_option === 1) {
      const containerOption1 = document.getElementById("option-1");
      containerOption1.style.display = "none";
    } else if (number_option === 2) {
      const containerOption2 = document.getElementById("option-2");
      containerOption2.style.display = "none";
    } else if (number_option === 4) {
      const containerOption4 = document.getElementById("option-4");
      containerOption4.style.display = "none";
    }
  };

  const handleEnter = (number_option) => {
    if (number_option === 1) {
      const containerOption1 = document.getElementById("option-1");
      containerOption1.style.display = "flex";
      const containerOption2 = document.getElementById("option-2");
      containerOption2.style.display = "none";
      const containerOption4 = document.getElementById("option-4");
      containerOption4.style.display = "none";
    } else if (number_option === 2) {
      const containerOption1 = document.getElementById("option-1");
      containerOption1.style.display = "none";
      const containerOption2 = document.getElementById("option-2");
      containerOption2.style.display = "flex";
      const containerOption4 = document.getElementById("option-4");
      containerOption4.style.display = "none";
    } else if (number_option === 4) {
      const containerOption1 = document.getElementById("option-1");
      containerOption1.style.display = "none";
      const containerOption2 = document.getElementById("option-2");
      containerOption2.style.display = "none";
      const containerOption4 = document.getElementById("option-4");
      containerOption4.style.display = "flex";
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const focusableElements = containerRefOption1.current.querySelectorAll(
        ".submenu-option-desktop"
      );
      const focusableArray = Array.prototype.slice.call(focusableElements);
      const currentIndex = focusableArray.indexOf(document.activeElement);

      if (event.key === "ArrowDown" || event.key === "ArrowRight") {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % focusableArray.length;
        focusableArray[nextIndex].focus();
      } else if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
        event.preventDefault();
        const prevIndex =
          (currentIndex - 1 + focusableArray.length) % focusableArray.length;
        focusableArray[prevIndex].focus();
      } else if (
        event.key === "Enter" &&
        document.activeElement.classList.contains("submenu-option-desktop")
      ) {
        document.activeElement.click();
      }
    };

    const container = containerRefOption1.current;
    if (container) {
      container.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (container) {
        container.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  useEffect(() => {
    const handleKeyDown2 = (event) => {
      const focusableElements = containerRefOption2.current.querySelectorAll(
        ".submenu-option-desktop"
      );
      const focusableArray = Array.prototype.slice.call(focusableElements);
      const currentIndex = focusableArray.indexOf(document.activeElement);

      if (event.key === "ArrowDown" || event.key === "ArrowRight") {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % focusableArray.length;
        focusableArray[nextIndex].focus();
      } else if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
        event.preventDefault();
        const prevIndex =
          (currentIndex - 1 + focusableArray.length) % focusableArray.length;
        focusableArray[prevIndex].focus();
      } else if (
        event.key === "Enter" &&
        document.activeElement.classList.contains("submenu-option-desktop")
      ) {
        document.activeElement.click();
      }
    };

    const container2 = containerRefOption2.current;
    if (container2) {
      container2.addEventListener("keydown", handleKeyDown2);
    }

    return () => {
      if (container2) {
        container2.removeEventListener("keydown", handleKeyDown2);
      }
    };
  }, []);

  useEffect(() => {
    const handleKeyDown3 = (event) => {
      if (
        event.key === "Enter" &&
        document.activeElement.classList.contains("option-3")
      ) {
        document.activeElement.click();
      }
    };

    const container3 = containerRefOption3.current;
    if (container3) {
      container3.addEventListener("keydown", handleKeyDown3);
    }

    return () => {
      if (container3) {
        container3.removeEventListener("keydown", handleKeyDown3);
      }
    };
  }, []);

  useEffect(() => {
    const handleKeyDown4 = (event) => {
      const focusableElements = containerRefOption4.current.querySelectorAll(
        ".submenu-option-desktop"
      );
      const focusableArray = Array.prototype.slice.call(focusableElements);
      const currentIndex = focusableArray.indexOf(document.activeElement);

      if (event.key === "ArrowDown" || event.key === "ArrowRight") {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % focusableArray.length;
        focusableArray[nextIndex].focus();
      } else if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
        event.preventDefault();
        const prevIndex =
          (currentIndex - 1 + focusableArray.length) % focusableArray.length;
        focusableArray[prevIndex].focus();
      } else if (
        event.key === "Enter" &&
        document.activeElement.classList.contains("submenu-option-desktop")
      ) {
        document.activeElement.click();
      }
    };

    const container4 = containerRefOption4.current;
    if (container4) {
      container4.addEventListener("keydown", handleKeyDown4);
    }

    return () => {
      if (container4) {
        container4.removeEventListener("keydown", handleKeyDown4);
      }
    };
  }, []);

  useEffect(() => {
    const handleKeyDown5 = (event) => {
      if (
        event.key === "Enter" &&
        document.activeElement.classList.contains("option-5")
      ) {
        document.activeElement.click();
      }
    };

    const container5 = containerRefOption5.current;
    if (container5) {
      container5.addEventListener("keydown", handleKeyDown5);
    }

    return () => {
      if (container5) {
        container5.removeEventListener("keydown", handleKeyDown5);
      }
    };
  }, []);

  useEffect(() => {
    const handleKeyDown6 = (event) => {
      if (
        event.key === "Enter" &&
        document.activeElement.classList.contains("option-6")
      ) {
        document.activeElement.click();
      }
    };

    const container6 = containerRefOption6.current;
    if (container6) {
      container6.addEventListener("keydown", handleKeyDown6);
    }

    return () => {
      if (container6) {
        container6.removeEventListener("keydown", handleKeyDown6);
      }
    };
  }, []);

  return (
    <header style={{ marginTop: `${barHeight}px` }} className="new-home-header">
      <nav className="new-home-nav">
        <div className="container-new-home nav-header">
          <img
            className="logo-header"
            src="https://www.afphabitat.com.pe/wp-content/themes/habitatpublico/images/logo.png"
            alt="habitat-logo"
          />
          <div className="desktop-header">
            <div
              className="header-option"
              onMouseEnter={() => {
                handleEnter(1);
              }}
              onMouseLeave={() => handleLeave(1)}
            >
              <div tabIndex="1" onFocus={toogleFocusOption1}>
                <span className="option-desktop-title">
                  Aportes y jubilación
                </span>
                <img
                  className="arrow-icon"
                  src={whietDownArrow}
                  alt="white-down-arrow"
                />
              </div>
              <div
                className="submenu-option-container-desktop"
                id="option-1"
                ref={containerRefOption1}
              >
                <div
                  tabIndex={"2"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl("https://www.afphabitat.com.pe/aporte-obligatorio/")
                  }
                >
                  <img className="icon-img" src={DolarCoin} alt="blog-icon" />
                  <span className="suboption-desktop-title">
                    Aporte obligatorio
                  </span>
                </div>
                <div
                  tabIndex={"3"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl("https://www.afphabitat.com.pe/aporte-voluntario/")
                  }
                >
                  <img className="icon-img" src={Coin} alt="spp-icon" />
                  <span className="suboption-desktop-title">
                    Aporte voluntario
                  </span>
                </div>
                <div
                  tabIndex={"4"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl("https://www.afphabitat.com.pe/multifondos/")
                  }
                >
                  <img className="icon-img" src={Wallet} alt="reforma-icon" />
                  <span className="suboption-desktop-title">Multifondos</span>
                </div>
                <div
                  tabIndex={"5"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl("https://www.afphabitat.com.pe/pensiones/")
                  }
                >
                  <img className="icon-img" src={SaveMoney} alt="video-icon" />
                  <span className="suboption-desktop-title">Jubilación</span>
                </div>
              </div>
            </div>

            <div
              className="header-option"
              onMouseEnter={() => {
                handleEnter(2);
              }}
              onMouseLeave={() => handleLeave(2)}
            >
              <div tabIndex={"6"} onFocus={toogleFocusOption2}>
                <span className="option-desktop-title">Etapas de la vida</span>
                <img
                  className="arrow-icon"
                  src={whietDownArrow}
                  alt="white-down-arrow"
                />
              </div>

              <div
                className="submenu-option-container-desktop"
                id="option-2"
                ref={containerRefOption2}
              >
                <div
                  tabIndex={"7"}
                  className="submenu-option-desktop"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/pronto-voy-a-aportar/"
                    )
                  }
                >
                  <div className="life-step-container">
                    <div className="bg-hover brown-bg-hover"></div>
                    <img
                      className="life-step-img"
                      src={SubMenu1}
                      alt="blue-man"
                    />
                    <div className="text-content brown-color">
                      <p>Pronto voy a aportar</p>
                    </div>
                  </div>
                </div>

                <div
                  tabIndex={"8"}
                  className="submenu-option-desktop"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/estoy-aportando-hace-poco/"
                    )
                  }
                >
                  <div className="life-step-container">
                    <div className="bg-hover blue-dark-bg-hover"></div>
                    <img
                      className="life-step-img"
                      src={SubMenu2}
                      alt="blue-man"
                    />
                    <div className="text-content blue-dark-color">
                      <p>Estoy aportando hace poco</p>
                    </div>
                  </div>
                </div>

                <div
                  tabIndex={"9"}
                  className="submenu-option-desktop"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/he-aportado-varios-anos/"
                    )
                  }
                >
                  <div className="life-step-container">
                    <div className="bg-hover blue-light-bg-hover"></div>
                    <img
                      className="life-step-img"
                      src={SubMenu3}
                      alt="blue-man"
                    />
                    <div className="text-content blue-light-color">
                      <p>He aportado varios años</p>
                    </div>
                  </div>
                </div>

                <div
                  tabIndex={"10"}
                  className="submenu-option-desktop"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/soy-trabajador-independiente/"
                    )
                  }
                >
                  <div className="life-step-container">
                    <div className="bg-hover purple-bg-hover"></div>
                    <img
                      className="life-step-img"
                      src={SubMenu4}
                      alt="blue-man"
                    />
                    <div className="text-content purple-color">
                      <p>Soy trabajador independiente</p>
                    </div>
                  </div>
                </div>

                <div
                  tabIndex={"11"}
                  className="submenu-option-desktop"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/estoy-proximo-a-jubilarme/"
                    )
                  }
                >
                  <div className="life-step-container">
                    <div className="bg-hover green-bg-hover"></div>
                    <img
                      className="life-step-img"
                      src={SubMenu5}
                      alt="blue-man"
                    />
                    <div className="text-content green-color">
                      <p>Estoy próximo a jubilarme</p>
                    </div>
                  </div>
                </div>

                <div
                  tabIndex={"12"}
                  className="submenu-option-desktop"
                  onClick={() =>
                    goToUrl("https://www.afphabitat.com.pe/soy-jubilado/")
                  }
                >
                  <div className="life-step-container">
                    <div className="bg-hover red-bg-hover"></div>
                    <img
                      className="life-step-img"
                      src={SubMenu6}
                      alt="blue-man"
                    />
                    <div className="text-content red-color">
                      <p>Me jubilé del trabajo</p>
                    </div>
                  </div>
                </div>

                <div
                  tabIndex={"13"}
                  className="submenu-option-desktop"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/soy-beneficiario-o-heredero/"
                    )
                  }
                >
                  <div className="life-step-container">
                    <div className="bg-hover yellow-light-bg-hover"></div>
                    <img
                      className="life-step-img"
                      src={SubMenu7}
                      alt="blue-man"
                    />
                    <div className="text-content yellow-light-color">
                      <p>Soy beneficiario o heredero</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              tabIndex={"14"}
              onFocus={toogleFocusOption3}
              ref={containerRefOption3}
              className="header-option option-3"
              onClick={() =>
                goToUrl("https://www.afphabitat.com.pe/rentabilidad/")
              }
            >
              <span className="option-desktop-title">Rentabilidad</span>
            </div>

            <div
              className="header-option"
              onMouseEnter={() => {
                handleEnter(4);
              }}
              onMouseLeave={() => handleLeave(4)}
            >
              <div tabIndex={"15"} onFocus={toogleFocusOption4}>
                <span className="option-desktop-title">Aprenda</span>
                <img
                  className="arrow-icon"
                  src={whietDownArrow}
                  alt="white-down-arrow"
                />
              </div>

              <div
                className="submenu-option-container-desktop"
                id="option-4"
                ref={containerRefOption4}
              >
                <div
                  tabIndex={"16"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/aprende-de-prevision/"
                    )
                  }
                >
                  <img className="icon-img" src={Blog} alt="blog-icon" />
                  <span className="suboption-desktop-title">Blog</span>
                </div>
                <div
                  tabIndex={"17"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/todo-sobre-el-sistema/"
                    )
                  }
                >
                  <img className="icon-img" src={SPP} alt="spp-icon" />
                  <span className="suboption-desktop-title">SPP</span>
                </div>
                <div
                  tabIndex={"18"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl(
                      "https://www.afphabitat.com.pe/reforma-del-sistema-de-pensiones/"
                    )
                  }
                >
                  <img className="icon-img" src={Reforma} alt="reforma-icon" />
                  <span className="suboption-desktop-title">Reforma AFP</span>
                </div>
                <div
                  tabIndex={"19"}
                  className="submenu-option-desktop sub-menu-opacity"
                  onClick={() =>
                    goToUrl("https://www.afphabitat.com.pe/facil-en-videos/")
                  }
                >
                  <img className="icon-img" src={Video} alt="video-icon" />
                  <span className="suboption-desktop-title">
                    Facil en Videos
                  </span>
                </div>
              </div>
            </div>

            <div
              tabIndex={"20"}
              onFocus={toogleFocusOption5}
              ref={containerRefOption5}
              className="header-option option-5"
              onClick={() =>
                goToUrl("https://www.afphabitat.com.pe/canales-de-atencion/")
              }
            >
              <span className="option-desktop-title">Atención al cliente</span>
            </div>

            <div
              tabIndex={"21"}
              ref={containerRefOption6}
              className="header-option option-6"
              onClick={() =>
                goToUrl("https://www.afphabitat.com.pe/empleadores/")
              }
            >
              <span className="option-desktop-title">Empleadores</span>
            </div>

            <div
              className="btn-change-habitat"
              onClick={() =>
                goToUrl(
                  "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=menu&utm_campaign=traspasos"
                )
              }
            >
              <span className="option-desktop-title">Cámbiate a HABITAT</span>
            </div>
          </div>
          <div className="mobile-header">
            <div className="btn-my-zone" onClick={() => irAlLogin()}>
              <img src={user} alt="user" />
              <span className="option-desktop-title">Mi Habitat Digital</span>
            </div>
            <div className="menu-right">
              <div className="menuToggle-main">
                <div className="bar-container" onClick={() => onClickBars()}>
                  <div className="bar bar1"> </div>
                  <div className="bar bar2"></div>
                  <div className="bar bar3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="menuButton-main" id="menuButton-main">
        {headerOptions.map((value, index) => (
          <HeaderRowOption key={index} value={value} />
        ))}
      </div>
    </header>
  );
};

export default Header;
