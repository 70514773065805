import React, { useState, useEffect } from "react";
import NotificationBar from "../components/NotificationBar";
import { useLocation } from "react-router-dom";
import { clienteWordPress } from "../config/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useNotification } from "../context/notificationbar/notificationBarContext";
import { SISTEMA_RUTAS } from "../data/sistemaRutas.js";

const NotificationWrapper = ({ children }) => {
    const { barHeight, setBarHeight } = useNotification();
    const location = useLocation();
    const [activeNotification, setActiveNotification] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await clienteWordPress.get(
                    "/wp-json/wp/v2/notification_bar"
                );

                const now = new Date();

                // 1. Filtrar notificaciones activas por fecha
                const activeNotifications = response.data.filter((item) => {
                    const startDate = new Date(item.acf.fecha_inicio);
                    const endDate = new Date(item.acf.fecha_fin);
                    return now >= startDate && now <= endDate;
                });

                // 2. Filtrar las notificaciones según ruta y sistemas
                const matchingNotifications = activeNotifications.filter((item) => {
                    const sistemas = item.acf.sistema || [];
                    return sistemas.some((sistema) => {
                        const regex = SISTEMA_RUTAS[sistema];
                        return regex && regex.test(location.pathname);
                    });
                });

                // 3. Priorizar una notificación si hay múltiples
                if (matchingNotifications.length > 0) {
                    const prioritizedNotification = prioritizeNotification(
                        matchingNotifications
                    );
                    setActiveNotification(prioritizedNotification);
                } else {
                    setActiveNotification(null);
                }
            } catch (error) {
                console.error("Error fetching notification:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotifications();
    }, [location.pathname]);

    const prioritizeNotification = (notifications) => {
        // Si existe una sola notificación, usarla directamente
        return notifications[0];
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <CircularProgress size={60} thickness={4} />
            </div>
        );
    }

    return (
        <>
            {activeNotification && (
                <NotificationBar
                    notification={activeNotification}
                    onHeightChange={(height) => setBarHeight(height)}
                />
            )}
            {children}
        </>
    );
};

export default NotificationWrapper;
