import React, { createContext, useContext, useState } from "react";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [barHeight, setBarHeight] = useState(0);

    return (
        <NotificationContext.Provider value={{ barHeight, setBarHeight }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
